import React, { FormEvent, useCallback, useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import {
  ActualBtn,
  GoogleBtn,
  Left,
  Right,
  SocialLogin,
  Wrapper,
  OrDivider,
  Form,
  InputLabel,
  Input,
  SubmitBtn,
  LeftContent,
  Logo,
  TermsCondition,
  SignupBtn,
  VerifyOtp,
  VerifyBtn,
  ResendOtp,
  EnteredEmailContainer,
  TimerContainer,
  Timer,
  RequestForOtp,
  SignupWithPasswordBtn,
  CaptchaSubText,
  AppsumoModal,
} from "./Login.styles";
import UNIVERSAL from "../../config/config";
import { useNavigate, useSearchParams } from "react-router-dom";

import googleLogo from "../../assets/googleLogo.png";

import { Link, TextField, Alert, Modal } from "@mui/material";
import { fetchUserProfile, setProfile } from "../../slices/user/userSlice";
import { useAppSelector } from "src/hooks/useAppSelector";
import { useAppDispatch } from "src/hooks/useAppDispatch";
import PhoneInput from "react-phone-input-2";
import OtpInput from "react-otp-input";
import EditEmailIcon from "../../assets/editEmail.svg";
import TimerIcon from "../../assets/timer.svg";
import { useCountdown } from "src/hooks/use-countdown";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { createStyles } from "src/config/theme";
import CircularProgress from "@mui/material/CircularProgress";
import {
  useGetLoginWithEmailOTPMutation,
  useGetSignupWithEmailOTPMutation,
  useVerifyLoginWithEmailOTPMutation,
  useVerifyReCaptchaTokenMutation,
  useVerifySignupWithEmailOTPMutation,
} from "src/api/authentication";
import { useSnackbar } from "notistack";
import {
  loginWithGoogle,
  signupWithGoogle,
  updateOAuth2Tokens,
} from "src/slices/authentication/authentication";
import { authKey } from "src/constants/storage";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
} from "react-google-recaptcha-v3";
import { useValidateAppsumoAuthCodeQuery } from "src/api/appsumo";

const Login = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [formEditType, setFormEditType] = useState("loginwithotp");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [checked, setChecked] = useState(false);
  const [enteredPhnNumber, setEnteredPhnNumber] = useState("");
  const [countryDialCode, setCountryDialCode] = useState("");
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [token, setToken] = useState("");
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);
  // const [appSumoCode, setAppsumoCode] = useState("");
  // const [utmSource, setUtmSource] = useState("");

  const [countdown, { hasCountdownEnded, resetAndStartCountdown }] =
    useCountdown({ start: 30 });

  const [signupError, setSignupError] = useState<string | boolean>(false);
  const [loginwithotpError, setLoginWithOtpError] = useState<string | boolean>(
    false
  );

  const authentication = useAppSelector((state) => state.authentication.user);
  const userLocation = useAppSelector(
    (state) => state.userLocation.userLocation
  );

  const dispatch = useAppDispatch();

  const [getLoginWithEmailOTP] = useGetLoginWithEmailOTPMutation();
  const [verifyLoginWithEmailOTP] = useVerifyLoginWithEmailOTPMutation();

  const [signupWithEmailOTP] = useGetSignupWithEmailOTPMutation();
  const [verifySignupWithEmailOTP] = useVerifySignupWithEmailOTPMutation();
  const [validateRecaptcha] = useVerifyReCaptchaTokenMutation();

  const searchParams = new URLSearchParams(window.location.search);
  const utm = searchParams.get("utm_source");
  const code = searchParams.get("code");

  const validateAppsumo = useValidateAppsumoAuthCodeQuery(
    { code: code || "" },
    { skip: !code }
  );

  const [formType, setFormType] = useState("loginwithotp");

  const isPhoneNumberRequired = !code || !validateAppsumo?.isSuccess;

  useEffect(() => {
    if (!code) return;
    if (validateAppsumo?.isSuccess && !validateAppsumo?.data?.is_user_exists) {
      setFormType("signup");
      setFormEditType("signup");
    }

    if (validateAppsumo?.isSuccess && validateAppsumo?.data?.is_user_exists) {
      dispatch(
        updateOAuth2Tokens({
          access_token: validateAppsumo?.data?.access_token,
          access_token_expiry: validateAppsumo?.data?.access_token_expiry,
          refresh_token: validateAppsumo?.data?.refresh_token,
          refresh_token_expiry: validateAppsumo?.data?.refresh_token_expiry,
        })
      );
      navigate("/dashboard");
      window.location.reload();
    }
  }, [validateAppsumo, code]);

  useEffect(() => {
    if (localStorage.getItem(authKey) && (!utm || !code)) {
      navigate("/dashboard");
    }
  }, []);

  const loginWithOtp = async (e: FormEvent, type: string) => {
    e.preventDefault();
    if (!email) {
      setLoginWithOtpError("Email is required");
      return;
    }
    setShowLoader(true);
    try {
      await validateRecaptcha({ recaptcha_key: token }).unwrap();
      setRefreshReCaptcha(!refreshReCaptcha);
      await getLoginWithEmailOTP({ email: email })
        .unwrap()
        .then((response) => {
          if (
            response.is_user_exists &&
            response.otp_sent &&
            response.email_verified
          ) {
            setFormType("verifyotp");
            resetAndStartCountdown();
          }
        });
      enqueueSnackbar(
        type !== "resendotp"
          ? "OTP sent to your entered email"
          : "OTP re-sent to your entered email",
        {
          variant: "success",
        }
      );
      setShowLoader(false);
    } catch (e: any) {
      setRefreshReCaptcha(!refreshReCaptcha);
      if (e.data.message) {
        enqueueSnackbar(e.data.message, { variant: "error" });
        setShowLoader(false);
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
        console.log(e);
        setShowLoader(false);
      }
    }
  };

  const handleVerifyOtp = async (e: FormEvent) => {
    e.preventDefault();
    if (otp.length < 6) {
      enqueueSnackbar("Please enter the 6 digits otp", { variant: "error" });
      return;
    }
    setShowLoader(true);
    try {
      await verifyLoginWithEmailOTP({
        email: email,
        otp: otp,
      })
        .unwrap()
        .then((response) => {
          if (
            (response?.user?.onboarding === true ||
              response?.user?.onboarding === "true") &&
            response?.user?.fb_access_token &&
            response.is_user_exists &&
            response.access_token
          ) {
            dispatch(fetchUserProfile())
              .then(() => navigate("/dashboard"))
              .catch((e) => {
                if (e.data.message) {
                  enqueueSnackbar(e.data.message, { variant: "error" });
                } else {
                  enqueueSnackbar("Something went wrong", { variant: "error" });
                }
              });
          } else if (
            response?.user?.onboarding === false ||
            response?.user?.onboarding === "false" ||
            !response?.user?.fb_access_token
          ) {
            navigate("/onboarding");
          }
        });
      enqueueSnackbar("Login Successful", {
        variant: "success",
      });
      setShowLoader(false);
    } catch (e: any) {
      if (e.data.message) {
        enqueueSnackbar(e.data.message, { variant: "error" });
        setShowLoader(false);
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
        console.log(e);
        setShowLoader(false);
      }
    }
  };

  const emailPatternVerifyRegex =
    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const singupWithOtp = async (e: FormEvent, type: string) => {
    e.preventDefault();
    let emailPatternVerify = emailPatternVerifyRegex.test(email);

    if (
      name === "" ||
      email === "" ||
      website === "" ||
      (isPhoneNumberRequired && countryDialCode === "") ||
      (isPhoneNumberRequired && mobile === "") ||
      !checked
    ) {
      setSignupError("Please fill all the fields");
      return;
    } else if (emailPatternVerify === false) {
      setSignupError("Please enter correct email address");
      return;
    } else {
      setSignupError(false);
      setShowLoader(true);
      try {
        await validateRecaptcha({ recaptcha_key: token }).unwrap();
        setRefreshReCaptcha(!refreshReCaptcha);
        await signupWithEmailOTP({
          name: name,
          email: email,
          mobile: mobile,
          website: website,
          countryDialCode: countryDialCode,
        })
          .unwrap()
          .then((response) => {
            if (response.is_user_exists) {
              enqueueSnackbar("Email already Exists", {
                variant: "info",
              });
            }
            if (!response.is_user_exists && response.otp_sent) {
              setFormType("verifyotp");
              resetAndStartCountdown();
              enqueueSnackbar(
                type !== "resendotp"
                  ? "OTP sent to your entered email"
                  : "OTP re-sent to your entered email",
                {
                  variant: "success",
                }
              );
            }
          });

        setShowLoader(false);
      } catch (e: any) {
        setRefreshReCaptcha(!refreshReCaptcha);
        if (e.data.message) {
          enqueueSnackbar(e.data.message, { variant: "error" });
          setShowLoader(false);
        } else {
          enqueueSnackbar("Something went wrong", { variant: "error" });
          console.log(e);
          setShowLoader(false);
        }
      }
    }
  };

  const handleResendOtp = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    if (formEditType === "loginwithotp") {
      loginWithOtp(e, "resendotp");
      setOtp("");
    }
    if (formEditType === "signup") {
      singupWithOtp(e, "resendotp");
      setOtp("");
    }
  };

  const handleVerifySignupOtp = async (e: FormEvent) => {
    e.preventDefault();
    if (otp.length < 6) {
      enqueueSnackbar("Please enter the 6 digits otp", { variant: "error" });
      return;
    }
    setShowLoader(true);
    try {
      await verifySignupWithEmailOTP({
        name: name,
        email: email,
        mobile: mobile,
        website: website,
        countryDialCode: countryDialCode,
        otp: otp,
        code: code ? code : undefined,
        utm_source: code ? utm || "" : undefined,
      })
        .unwrap()
        .then(() => {
          navigate("/onboarding");
        });
      enqueueSnackbar("Signup Successful", {
        variant: "success",
      });
      setShowLoader(false);
    } catch (e: any) {
      if (e.data.message) {
        enqueueSnackbar(e.data.message, { variant: "error" });
        setShowLoader(false);
      } else {
        enqueueSnackbar("Something went wrong", { variant: "error" });
        console.log(e);
        setShowLoader(false);
      }
    }
  };

  useEffect(() => {
    setSignupError(false);
  }, [formType]);

  useEffect(() => {
    /* global google */
    const _window = window as any;

    if (_window.google) {
      _window.google?.accounts?.id?.initialize({
        client_id: UNIVERSAL.GOOGLE_LOGIN_CLIENT_ID,
        callback: handleCallBackResponse,
      });

      _window.google?.accounts?.id?.renderButton(
        document.getElementById("googleLoginBtn"),
        {
          theme: "outline",
          type: "standard",
          size: "large",
          text: "Sign in with Google",
        }
      );
    }
  }, []);

  const handleCallBackResponse = (response: any) => {
    dispatch(loginWithGoogle(response.credential))
      .unwrap()
      .catch(() =>
        dispatch(signupWithGoogle(response.credential)).then(() =>
          dispatch(loginWithGoogle(response.credential))
        )
      );
  };

  function handleEnteredPhoneNumber(phone: any, data: any) {
    setEnteredPhnNumber(phone);
    setCountryDialCode(data.dialCode);
    let dialCodeLength = data.dialCode.length;
    let number = phone.slice(dialCodeLength);
    setMobile(number);
  }

  const handleChangeToken = useCallback((val: string) => {
    setToken(val);
  }, []);

  const isAppsumoVerificationModal =
    code && validateAppsumo?.isLoading ? true : false;

  return (
    <Wrapper>
      <Left>
        <LeftContent>
          <Logo>
            <img
              src="https://drbgg29kvmub6.cloudfront.net/assets/animationFiles/companylogo.svg"
              alt="ad360-logo"
            />
          </Logo>
          <h2>
            Welcome to our&nbsp;<h3> AI </h3>&nbsp;Dashboard
          </h2>
          <p>
            See all the analytics and grow your data remotely, from anywhere!
          </p>
        </LeftContent>
      </Left>
      <Right>
        <GoogleReCaptcha
          onVerify={handleChangeToken}
          refreshReCaptcha={refreshReCaptcha}
        />
        {formType === "signup" && (
          <>
            <SocialLogin>
              <h3>Sign up for an account</h3>
              <p>Enter these below details to create your account</p>
              <GoogleBtn>
                <div id="googleLoginBtn" />
                <ActualBtn>
                  <img src={googleLogo} />
                  <p>Sign in with Google</p>
                </ActualBtn>
              </GoogleBtn>
            </SocialLogin>
            <OrDivider>Or signup with email</OrDivider>
            <Form onSubmit={(e: FormEvent) => singupWithOtp(e, "sendotp")}>
              {signupError && formType === "signup" && (
                <Alert sx={{ marginBottom: "1rem" }} severity="error">
                  {signupError}
                </Alert>
              )}
              <Input>
                <InputLabel>Name</InputLabel>
                <TextField
                  size="small"
                  fullWidth
                  placeholder="Enter your full name"
                  style={{ borderRadius: "50%" }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Input>
              <Input>
                <InputLabel>Email address</InputLabel>
                <TextField
                  type="email"
                  size="small"
                  fullWidth
                  placeholder="Enter your email ID"
                  style={{
                    borderRadius: "50%",
                  }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Input>
              <Input>
                <InputLabel>Your Company Website</InputLabel>
                <TextField
                  type="text"
                  size="small"
                  fullWidth
                  placeholder="Enter your company website"
                  style={{
                    borderRadius: "50%",
                  }}
                  value={website}
                  onChange={(e) => setWebsite(e.target.value)}
                />
              </Input>
              {isPhoneNumberRequired ? (
                <Input>
                  <InputLabel>Phone Number</InputLabel>
                  <PhoneInput
                    country={
                      userLocation
                        ? userLocation.toString().toLowerCase()
                        : "in"
                    }
                    containerClass="signupPhoneContainer"
                    inputClass="signupSearch"
                    dropdownClass="signupDropdown"
                    value={enteredPhnNumber}
                    countryCodeEditable={false}
                    placeholder="Your Phone Number"
                    onChange={(phone, data) =>
                      handleEnteredPhoneNumber(phone, data)
                    }
                  />
                </Input>
              ) : null}
              <CaptchaSubText>
                <p>
                  This site is protected by reCAPTCHA and the Google&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://policies.google.com/privacy"
                  >
                    Privacy policy
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://policies.google.com/terms"
                  >
                    Terms of service
                  </a>
                  &nbsp; apply
                </p>
              </CaptchaSubText>
              <TermsCondition>
                <input
                  type="checkbox"
                  name="termscondition"
                  id="termscondition"
                  onChange={() => setChecked(!checked)}
                />
                <label htmlFor="termscondition">
                  By clicking, you agree to our
                </label>
                <a
                  href="https://zocket.com/terms.php"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms & Condition
                </a>
              </TermsCondition>
              <SignupBtn>
                <SignupWithPasswordBtn
                  sx={{
                    width: "20rem",
                    borderRadius: "1rem",
                    textTransform: "none",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                  variant="contained"
                  onClick={(e: FormEvent) => singupWithOtp(e, "sendotp")}
                  disabled={showLoader}
                >
                  <span></span>
                  Request for an OTP{" "}
                  {showLoader ? (
                    <CircularProgress
                      style={styles.circularProgressStyle}
                      size={20}
                    />
                  ) : (
                    <ArrowRightAltIcon />
                  )}{" "}
                </SignupWithPasswordBtn>
                <p>
                  Already have an account{" "}
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setFormType("loginwithotp");
                      setFormEditType("loginwithotp");
                    }}
                  >
                    Login
                  </Link>
                </p>
              </SignupBtn>
            </Form>
          </>
        )}

        {formType === "loginwithotp" && (
          <>
            <SocialLogin>
              <h3>Login to your account</h3>
              <p>Enter your credentials to access your account</p>
              <GoogleBtn>
                <div id="googleLoginBtn" />
                <ActualBtn>
                  <img src={googleLogo} />
                  <p>Sign in with Google</p>
                </ActualBtn>
              </GoogleBtn>
            </SocialLogin>
            <OrDivider>Or sign in with email</OrDivider>
            <Form onSubmit={(e: FormEvent) => loginWithOtp(e, "loginwithotp")}>
              {loginwithotpError && formType === "loginwithotp" && (
                <Alert sx={{ marginBottom: "1rem" }} severity="error">
                  {loginwithotpError}
                </Alert>
              )}
              <Input>
                <InputLabel>Email address</InputLabel>
                <TextField
                  type="email"
                  size="small"
                  fullWidth
                  placeholder="Enter your email ID"
                  style={{
                    borderRadius: "50%",
                  }}
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setLoginWithOtpError(false);
                  }}
                />
              </Input>
              <CaptchaSubText>
                <p>
                  This site is protected by reCAPTCHA and the Google&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://policies.google.com/privacy"
                  >
                    Privacy policy
                  </a>
                  &nbsp;and&nbsp;
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://policies.google.com/terms"
                  >
                    Terms of service
                  </a>
                  &nbsp; apply
                </p>
              </CaptchaSubText>

              <SubmitBtn>
                <RequestForOtp
                  type="submit"
                  sx={{
                    width: "20rem",
                    borderRadius: "1rem",
                    textTransform: "none",
                    fontFamily: "Montserrat, sans-serif",
                  }}
                  variant="contained"
                  disabled={showLoader}
                >
                  <span></span>
                  Sign in{" "}
                  {showLoader ? (
                    <CircularProgress
                      style={styles.circularProgressStyle}
                      size={20}
                    />
                  ) : (
                    <ArrowRightAltIcon />
                  )}{" "}
                </RequestForOtp>
                <p>
                  Don&apos;t have an account{" "}
                  <Link
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      setFormType("signup");
                      setEmail("");
                      setFormEditType("signup");
                    }}
                  >
                    Sign up
                  </Link>
                </p>
              </SubmitBtn>
            </Form>
          </>
        )}

        {formType === "verifyotp" && (
          <VerifyOtp>
            <h2>Please enter OTP</h2>
            <p>Please enter your OTP and signup to the application</p>
            <EnteredEmailContainer>
              <span>{email}</span>
              <img
                src={EditEmailIcon}
                alt="edit-email-icon"
                onClick={() => {
                  setChecked(false);
                  setFormType(formEditType);
                }}
              />
            </EnteredEmailContainer>
            <Form
              onSubmit={(e: FormEvent) =>
                formEditType === "loginwithotp"
                  ? handleVerifyOtp(e)
                  : handleVerifySignupOtp(e)
              }
            >
              <TimerContainer>
                <OtpInput
                  value={otp}
                  onChange={(e: string) => setOtp(e)}
                  numInputs={6}
                  inputStyle="otpInput"
                  containerStyle="otpInputContainer"
                  shouldAutoFocus
                  renderInput={(props) => <input {...props} />}
                />
                <Timer>
                  <img src={TimerIcon} alt="timer-icon" />
                  <span>00:{String(countdown).padStart(2, "0")}</span>
                </Timer>
              </TimerContainer>
              <VerifyBtn disabled={showLoader}>
                <span></span>
                Verify and proceed{" "}
                {showLoader ? (
                  <CircularProgress
                    style={styles.circularProgressStyle}
                    size={20}
                  />
                ) : (
                  <ArrowRightAltIcon />
                )}{" "}
              </VerifyBtn>
            </Form>
            <ResendOtp hasCountdownEnded={hasCountdownEnded}>
              <p>Didn’t receive OTP?</p>
              <button
                onClick={(e) => handleResendOtp(e)}
                disabled={!hasCountdownEnded}
              >
                Resend
              </button>
            </ResendOtp>
          </VerifyOtp>
        )}
      </Right>
      <Modal open={isAppsumoVerificationModal}>
        <AppsumoModal>
          <CircularProgress />
          <h4>Verifying your appsumo account</h4>
          <p>Please wait while we verify your appsumo account</p>
        </AppsumoModal>
      </Modal>
    </Wrapper>
  );
};

export default Login;

const styles = createStyles({
  circularProgressStyle: {
    color: "white",
  },
});
